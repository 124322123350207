import { __decorate } from "tslib";
import { Component, Vue, Ref, Watch, } from 'vue-property-decorator';
import { WorkPlatformService } from '@/sevices';
import { Loading } from '@cloudpivot-hermes/common-components';
import { namespace } from 'vuex-class';
const workPlatformModule = namespace('workPlatform');
let WorkPlatform = class WorkPlatform extends Vue {
    constructor() {
        super(...arguments);
        this.tabKey = 0;
        this.tabs = [
            { name: '待办', key: 0, list: [], total: 0 },
            { name: '待阅', key: 1, list: [], total: 0 },
        ];
        this.pageLoading = false;
    }
    badgeCount(count) {
        return count > 99 ? '99+' : count === 0 ? '' : count;
    }
    // 切换成功组织后再去获取数据
    onChangeIsSetOrg(v) {
        if (v) {
            this.getTabs();
        }
    }
    beforeDestroy() {
        this.resetWorkPlatform();
        window.removeEventListener('message', () => { });
    }
    async created() {
        // this.getTabs();
        window.addEventListener('message', this.reloadPage, false);
    }
    /**
     * 如果流程发生变化，则刷新数据
     */
    async reloadPage(evt) {
        const isFreeLogin = localStorage.IS_FREELOGIN;
        if (evt.data === 'reload' && evt.origin === window.location.origin && !isFreeLogin) {
            this.getTabs();
        }
    }
    toggleTab(key) {
        this.tabKey = key;
    }
    /**
     * 获取待办待阅
     */
    getTabs() {
        this.pageLoading = true;
        Promise.all([this.getTodoList(), this.getToreadList()]).then((res) => {
            if (Array.isArray(res)) {
                res.forEach((item, index) => {
                    this.tabs[index].list = item.data || [];
                    this.tabs[index].total = item.total || 0;
                });
            }
        }).finally(() => {
            this.pageLoading = false;
        });
    }
    /**
     * 获取待办
     */
    getTodoList() {
        const params = {
            todoType: '1',
            page: 0,
            size: 3,
        };
        return WorkPlatformService.getTodoList(params).then((res) => {
            if (res.success) {
                return res;
            }
            else {
                return {};
            }
        });
    }
    /**
     * 获取待阅
     */
    getToreadList() {
        const params = {
            todoType: '2',
            page: 0,
            size: 3,
        };
        return WorkPlatformService.getTodoList(params).then((res) => {
            if (res.success) {
                return res;
            }
            else {
                return {};
            }
        });
    }
    /**
     * 搜索应用
     */
    appSearch(key) {
        this.appList.search(key);
    }
    goPage() {
        this.$router.push({
            name: 'flow-center',
            query: {
                todoType: '1',
            },
        });
    }
    showMore() {
        this.$router.push({
            name: 'work-platform-more',
        });
    }
};
__decorate([
    workPlatformModule.State('isSetOrg')
], WorkPlatform.prototype, "isSetOrg", void 0);
__decorate([
    workPlatformModule.Mutation('resetWorkPlatform')
], WorkPlatform.prototype, "resetWorkPlatform", void 0);
__decorate([
    Ref('appList')
], WorkPlatform.prototype, "appList", void 0);
__decorate([
    Watch('isSetOrg', { immediate: true })
], WorkPlatform.prototype, "onChangeIsSetOrg", null);
WorkPlatform = __decorate([
    Component({
        name: 'WorkPlatform',
        components: {
            TodoList: () => import('./todo-list.vue'),
            AppList: () => import('./app-list.vue'),
            RecentList: () => import('./recent-list.vue'),
            Loading,
        },
    })
], WorkPlatform);
export default WorkPlatform;
